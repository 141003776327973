import React, { Children } from "react"
import { graphql, Link } from "gatsby"
import Header from "../../layouts/header"
import Footer from "../../components/custom-components/FooterCream"
import FloatingBtn from "../../components/custom-components/floatingBtn"
import bg from "../../images/news-event/news-event-banner.webp"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "../../css/custom-css/custom-news-details.css"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import Seo from "../../components/seo"

const NewsTemplate = props => {
  const { title, createdAt, images, newsEventsDate } = props.data.news
  console.log(props.data.news)
  const pathToImage = getImage(images)
  const richContent = props.data.news.description
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <>
            {" "}
            <img
              class="alignleft"
              width="400"
              src={node.data.target.fluid.src}
              alt="news-detail-img"
            />
          </>
        )
      },
    },

    renderMark: {},
  }

  return (
    <>
      <Seo title={title} />
      <Header />
      <FloatingBtn />
      <div class="page-content bg-white">
        <div
          class="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div class="container">
            <div class="dlab-bnr-inr-entry">
              <h1 class="text-white">News and Events</h1>
              <div class="breadcrumb-row">
                <ul class="list-inline">
                  <li>
                    <Link to="/index">Home</Link>
                  </li>
                  <li>News and Events</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="content-area">
          <div class="container">
            <div class="custom-back-btn">
              <Link to={"/news-page/news-event"}>
                <span class="custom-back">
                  <i class="fa fa-arrow-left"></i>Back
                </span>
              </Link>
            </div>
            <div class="row">
              <div class="col-lg-9 m-b30">
                <div class="blog-post blog-single">
                  <div class="dlab-post-meta">
                    <ul>
                      <li class="post-date">
                        {" "}
                        <strong>{newsEventsDate}</strong>{" "}
                      </li>
                    </ul>
                  </div>
                  <div class="dlab-post-title ">
                    {/* <h4 class="post-title m-t0">{title}</h4> */}
                    <h4 class="post-title m-t0">
                      {title}
                    </h4>
                  </div>
                  <div class="dlab-post-media dlab-img-effect zoom-slow">
                    <GatsbyImage image={pathToImage} alt="news-primary-img" />
                  </div>
                  <br />
                  <div class="dlab-post-text/ news-detail">
                    {richContent && renderRichText(richContent, options)}
                  </div>
                </div>
              </div>

              <div class="col-lg-3">
                <aside class="side-bar sticky-top">
                  <div class="widget widget_archive">
                    <h5 class="widget-title style-1">Categories List</h5>
                    <ul>
                      <li title="News">
                        <Link to={"../news"}>News</Link>
                      </li>
                      <li title="Event">
                        <Link to={"../event"}>Event</Link>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

// export const query = graphql`
//   query getInfo($slug: String) {
//     news: contentfulNewsevents(slug: { eq: $slug }) {
//       title
//       slug
//       createdAt(formatString: "DD MMMM YYYY")
//       images {
//         gatsbyImageData
//       }
//       description {
//         raw
//         references {
//           ... on ContentfulAsset {
//             contentful_id
//             fluid(maxWidth: 300) {
//               ...GatsbyContentfulFluid_withWebp
//             }
//             __typename
//           }
//         }
//       }
//     }
//   }
// `
// export const query = graphql`
//   query getInfo($slug: String) {
//     news: contentfulNewsevents(slug: { eq: $slug }) {
//       title
//       slug
//       createdAt(formatString: "DD MMMM YYYY")
//       images {
//         gatsbyImageData
//       }
//       description {
//         raw
//       }
//     }
//   }
// `
export const query = graphql`
  query getInfo($slug: String) {
    news: contentfulNewsevents(slug: { eq: $slug }) {
      title
      slug
      createdAt(formatString: "DD MMMM YYYY")
      newsEventsDate(formatString: "DD MMMM YYYY")
      images {
        gatsbyImageData
      }
      description {
        raw
      }
    }
  }
`

export default NewsTemplate
